<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="变动类型" prop="billType">-->
<!--                <a-select placeholder="请选择变动类型" v-model="queryParam.billType" style="width: 100%" allow-clear>-->
<!--                  <a-select-option v-for="(d, index) in dict.type.bill_type" :key="index" :value="d.value">{{ d.label }}</a-select-option>-->
<!--                </a-select>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
            <a-col :md="8" :sm="24">
              <a-form-item label="状态" prop="status">
                <a-select placeholder="请选择状态" v-model="queryParam.status" style="width: 100%" allow-clear>
                  <a-select-option v-for="(d, index) in dict.type.water_state" :key="index" :value="d.value">{{ d.label }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="提现类型" prop="status">-->
<!--                <a-select placeholder="提现类型" v-model="queryParam.withdrawType" style="width: 100%" allow-clear>-->
<!--                  <a-select-option v-for="(d, index) in dict.type.withdraw_type" :key="index" :value="d.value">{{ d.label }}</a-select-option>-->
<!--                </a-select>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="收益类型" prop="type">-->
<!--                <a-select placeholder="请选择收益类型" v-model="queryParam.type" style="width: 100%" allow-clear>-->
<!--                  <a-select-option v-for="(d, index) in dict.type.money_type" :key="index" :value="d.value">{{ d.label }}</a-select-option>-->
<!--                </a-select>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="订单编号" prop="orderNo">
                  <a-input v-model="queryParam.orderNo" placeholder="请输入订单编号" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="账单标题" prop="title">
                  <a-input v-model="queryParam.title" placeholder="请输入账单标题" allow-clear/>
                </a-form-item>
              </a-col>

            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['biz:account:add']">-->
<!--          <a-icon type="plus" />新增-->
<!--        </a-button>-->
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['biz:account:edit']">
          <a-icon type="edit" />修改
        </a-button>
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['biz:account:remove']">-->
<!--          <a-icon type="delete" />删除-->
<!--        </a-button>-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['biz:account:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        :billTypeOptions="dict.type.bill_type"
        :typeOptions="dict.type.money_type"
        :statusOptions="dict.type.water_state"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="billType" slot-scope="text, record">
          <dict-tag :options="dict.type['bill_type']" :value="record.billType"/>
        </span>
        <span slot="type" slot-scope="text, record">
          <dict-tag :options="dict.type['money_type']" :value="record.type"/>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="status" slot-scope="text, record">
          <dict-tag :options="dict.type['water_state']" :value="record.status"/>
        </span>
        <span slot="withdrawType" slot-scope="text, record">
          <dict-tag :options="dict.type['withdraw_type']" :value="record.withdrawType"/>
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['biz:account:edit']" />
          <a v-show="record.status == 2" @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['biz:account:edit']">
            <a-icon type="edit" />审核
          </a>
<!--          <a-divider type="vertical" v-hasPermi="['biz:account:edit']" />-->
<!--          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['biz:account:edit']">-->
<!--            <a-icon type="edit" />修改-->
<!--          </a>-->
<!--          <a-divider type="vertical" v-hasPermi="['biz:account:remove']" />-->
<!--          <a @click="handleDelete(record)" v-hasPermi="['biz:account:remove']">-->
<!--            <a-icon type="delete" />删除-->
<!--          </a>-->
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageAccount,listAccount, delAccount } from '@/api/biz/accountAudit'
import CreateForm from './modules/AuditForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Account',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  dicts: ['bill_type', 'money_type', 'water_state', 'withdraw_type'],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        billType: null,
        type: null,
        orderNo: null,
        title: null,
        status: null,
        withdrawType: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: '主键id',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },

        {
          title: '用户ID',
          dataIndex: 'userId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用户昵称',
          dataIndex: 'nickname',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '变动前金额',
          dataIndex: 'beforeMoney',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '变动金额',
          dataIndex: 'changeMoney',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '变动后金额',
          dataIndex: 'afterMoney',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '标志符',
          dataIndex: 'sign',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '变动类型',
          dataIndex: 'billType',
          scopedSlots: { customRender: 'billType' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '收益类型',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '订单编号',
          dataIndex: 'orderNo',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '账单标题',
        //   dataIndex: 'title',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '插入时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // `withdraw_type` varchar(5) DEFAULT '0' COMMENT '提现方式: 0:银行卡 1：支付宝 2：微信',
        {
          title: '提现方式',
          dataIndex: 'withdrawType',
          scopedSlots: { customRender: 'withdrawType' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询用户账户流水列表 */
    getList () {
      this.loading = true
     pageAccount(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        billType: undefined,
        type: undefined,
        orderNo: undefined,
        title: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delAccount(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('biz/userAccountBill/export', {
            ...that.queryParam
          }, `用户账户流水_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
