<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
<!--      <a-form-model-item label="用户id" prop="userId" v-if="formType === 1">-->
<!--        <a-input v-model="form.userId" placeholder="请输入用户id" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="账单记录id" prop="goldBillId" v-if="formType === 1">-->
<!--        <a-input v-model="form.goldBillId" placeholder="请输入账单记录id" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="消费钻石所在房间id" prop="roomId" v-if="formType === 1">-->
<!--        <a-input v-model="form.roomId" placeholder="请输入消费钻石所在房间id" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="变动前金额" prop="beforeMoney" >-->
<!--        <a-input v-model="form.beforeMoney" placeholder="请输入变动前金额" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="变动金额" prop="changeMoney" >-->
<!--        <a-input v-model="form.changeMoney" placeholder="请输入变动金额" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="变动后金额" prop="afterMoney" >-->
<!--        <a-input v-model="form.afterMoney" placeholder="请输入变动后金额" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="标志符 + -" prop="sign" >-->
<!--        <a-input v-model="form.sign" placeholder="请输入标志符 + -" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="变动类型" prop="billType" >-->
<!--        <a-select placeholder="请选择变动类型" v-model="form.billType">-->
<!--          <a-select-option v-for="(d, index) in billTypeOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="收益类型" prop="type" >-->
<!--        <a-select placeholder="请选择收益类型" v-model="form.type">-->
<!--          <a-select-option v-for="(d, index) in typeOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="订单编号" prop="orderNo" >-->
<!--        <a-input v-model="form.orderNo" placeholder="请输入订单编号" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="账单标题" prop="title" >-->
<!--        <a-input v-model="form.title" placeholder="请输入账单标题" />-->
<!--      </a-form-model-item>-->

<!--      状态 0 冻结中 1 正常  2 审核中 3 驳回 4 已到账-->
      <a-form-model-item label="状态" prop="status" >
        <a-select placeholder="请选择状态" v-model="form.status">
          <a-select-option :key="0">请选择</a-select-option>
          <a-select-option :key="1" :value="2">待审核</a-select-option>
          <a-select-option :key="2" :value="3">驳回</a-select-option>
          <a-select-option :key="3" :value="4">通过</a-select-option>
<!--          <a-select-option v-for="(d, index) in statusOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>-->
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入备注" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            提交
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getAccount, addAccount, updateAccount } from '@/api/biz/accountAudit'

export default {
  name: 'CreateForm',
  props: {
    billTypeOptions: {
      type: Array,
      required: true
    },
    typeOptions: {
      type: Array,
      required: true
    },
    statusOptions: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        goldBillId: null,

        roomId: null,

        beforeMoney: null,

        changeMoney: null,

        afterMoney: null,

        sign: null,

        billType: null,

        type: null,

        orderNo: null,

        title: null,

        createTime: null,

        remark: null,

        status: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        goldBillId: null,
        roomId: null,
        beforeMoney: null,
        changeMoney: null,
        afterMoney: null,
        sign: null,
        billType: null,
        type: null,
        orderNo: null,
        title: null,
        createTime: null,
        remark: null,
        status: null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getAccount({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '审核'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateAccount(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addAccount(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
